<template>
  <section class="content-loading" id="loading-consulta">
    <img src="./../../assets/images/pages/logo-soc-nuevo.png" alt="">
    <h3>Procesando... </h3>
  </section>
</template>

<script>
export default {
  data() {
    return {
      email: '',
      caso: '',

      key: '',
      SocGuid: ''
    }
  },

  mounted () {
    const hasAccess = JSON.parse(localStorage.getItem("userInfo"))
    hasAccess ? this.getApikey() : this.$router.push("/login").catch(() => {})

    const userInfoJson = localStorage.getItem('userInfo')
    const { SocGuid } = JSON.parse(userInfoJson)
    this.SocGuid = SocGuid

    this.caso = this.$route.params.id
    // this.sendConsultaBuro()
    this.validateConsulta()
  },

  methods: {

    async validateConsulta () {

      try {
        
        this.$vs.loading({
          container: '#loading-consulta',
          scale: 0.6
        })

        const { Total, ReasonBlocked, IsPermitted } = await this.getConsultasSISEC()

        if ( !IsPermitted ) {

          this.$vs.notify({
            title: 'Error',
            text: ReasonBlocked,
            color: 'danger',
            position: 'top-right'
          })

          setTimeout(() => this.$router.push('/buroCredito'), 2000)

        }

        this.sendConsultaBuro()

      } catch (error) {
        
        console.error(error)

        this.$vs.notify({
          title:'Ocurrio un error de sistema en token sisec',
          text:error,
          color:'danger',
          position:'top-right'
        })

      }

    },

    async getTokenSisec () {
      try {
        const objRequest = {
          username: 'user',
          password: 'user',
          grant_type: 'password'
        }

        const URL_API_SISEC = 'https://apicirculocredito.sisec.mx/token'

        let formBody = [];
        for (const property in objRequest) {
          let encodedKey = encodeURIComponent(property)
          let encodedValue = encodeURIComponent(objRequest[property])
          formBody.push(`${encodedKey}=${encodedValue}`)
        }
        formBody = formBody.join("&")

        const data = await this.$axios.post(URL_API_SISEC, formBody,{headers : {'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'}})

        return data

      } catch (error) {

        console.error('Token error')

        this.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })

        return false
      }
    },

    async getConsultasSISEC () {
      try {
        
        const TOKEN_SISEC = await this.getTokenSisec()
        const { access_token } = TOKEN_SISEC.data

        const userInfoJson = localStorage.getItem('userInfo')
        const { SocGuid } = JSON.parse(userInfoJson)
  
        if ( !SocGuid ) {

          return { Total: 0, ReasonBlocked: 'El bróker no tiene la facultad de realizar consultas.', IsPermitted: false }

        }

        const objRequest = {
          EmployeeSOCId: SocGuid,
          PersonType: 'Fisico'
        }

        //const URL_API_SISEC = 'https://apicirculocredito.sisec.mx/api/CirculoCredito/Estado'
        // const URL_API_SISEC = 'https://apicirculocredito.sisec.mx/api/CirculoCredito/Estado' // PROD

        const URL_API_SISEC = 'https://apicirculocredito.sisec.mx/api/CirculoCredito/Consulta'       // buro de credito

        const resp = await this.$axios.post(URL_API_SISEC, objRequest, { 
          headers : {
            'Authorization': `Bearer ${access_token}`,
            'Content-Type': 'application/json; charset=UTF-8'
          }
        })

        const { status, data } = resp

        if ( status !== 200 ) {
          this.$vs.notify({
            title:'Ocurrio un error en Consultas disponibles',
            text:'Ocurrió un problema, inténtelo nuevamente más tarde.',
            color:'danger',
            position:'top-right'
          })

          return { Total: 0, ReasonBlocked: 'Ocurrió un problema, inténtelo nuevamente más tarde.', IsPermitted: false }
        }

        return data

      } catch (error) {
        console.error('Availability error')

        this.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })

        return { Total: null, ReasonBlocked: null, IsPermitted: null }
      }
    },

    async sendConsultaBuro () {

      try {

        // Token Buro de Credito
        const { token_error, token_error_description, access_token, token_type } = await this.getTokenBuro()
        if ( token_error ) {

          this.$vs.notify({
            title: token_error,
            text: token_error_description,
            color: 'danger',
            position: 'top-right'
          })

          setTimeout(() => this.$router.push('/buroCredito'), 2000)

          return

        }

        // Peticion Reporte de Credito
        const { error_consulta, error_description, objRespuesta } = await this.getReporteBuroCredito({ access_token, token_type })
        if ( error_consulta ) {

          this.$vs.notify({
            title: error_consulta,
            text: error_description,
            color: 'danger',
            position: 'top-right'
          })

          setTimeout(() => this.$router.push('/buroCredito'), 2000)

          return

        }
        
        const { reporte, idBuroCreditoConsulta, emailSolicitante, isReusedScore } = objRespuesta

        this.$vs.notify({
          title: 'Registro exitoso',
          text: `La consulta a buro de credito ha sido exitosa`,
          position: 'top-right'
        })

        // Registro en SISEC 
        const { errorConsultaSisec, strErrorConsultaSisec, data  } = await this.consultarSISEC({ reporte, idBuroCreditoConsulta, emailSolicitante, isReusedScore })

        if ( errorConsultaSisec ) {

          this.$vs.notify({
            title: errorConsultaSisec,
            text: strErrorConsultaSisec,
            color: 'danger',
            position: 'top-right'
          })

          setTimeout(() => this.$router.push('/buroCredito'), 2000)

          return

        }

        this.$vs.notify({
          title: 'Registro exitoso en SISEC',
          text: `Se registro el folio ${data}`,
          position: 'top-right'
        })

        const URL_FORMATO_REPORTE = `https://cotizador.socasesores.com/admin/formatos/Formato_Reporte_Buro_Credito_14_09_2023.php?intId=${idBuroCreditoConsulta}`

        setTimeout(() => location.replace(URL_FORMATO_REPORTE), 3000)

      } catch (error) {
        console.error('Error sendConsultaBuro ' + error)

        this.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })

        return false
      }

    },

    async getTokenBuro () {
      
      try {

        const objRequestToken = {
          strApiKey: this.key,
          strMetodo: 'GetTokenBuro'
        }

        const { data } = await this.$axios.post('/',objRequestToken,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})

        const { intEstatus, strError, objRespuesta } = data

        if ( intEstatus ) throw(strError)

        const { access_token, token_type } = objRespuesta
        return { token_error: null, token_error_description: null, access_token, token_type }

      } catch (error) {
        console.error('Token error')

        return { token_error: error, token_error_description: 'Error de sistema en token', access_token: null, token_type: null }
      }

    },

    async getReporteBuroCredito ({ access_token, token_type }) {

      try {
       
        const objRequestReporte = {
          strApiKey: this.key,
          strMetodo: 'GetReporteBuroCredito',
          objReporte: {
            caso: this.caso,
            access_token,
            token_type
          }
        }

        const { data } = await this.$axios.post('/',objRequestReporte,{headers : {'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'}})
        const { intEstatus, strError, objRespuesta } = data

        if ( intEstatus ) throw(strError)

        return { error_consulta: null, error_description: null, objRespuesta }

      } catch (error) {
        console.error('Reporte error')

        return { error_consulta: error, error_description: 'Error de sistema en reporte', objRespuesta: null }
      }

    },

    async consultarSISEC({ reporte, idBuroCreditoConsulta, emailSolicitante, isReusedScore }) {    

      try {

        const { strErrorSolicitante, objContenidoSolicitante } = await this.dameSolicitante({ email: emailSolicitante })

        if ( strErrorSolicitante ) {

          return this.$vs.notify({
            title: 'Error en solicitante',
            text: strErrorSolicitante,
            color: 'danger',
            position: 'top-right'
          })

        }

        const dataSolicitante = objContenidoSolicitante

        let sexo ;
        if (dataSolicitante.Sexo == 1) {
          sexo = 1
        }else{
          sexo = 2
        }

        let regimenMatrimonial;

        switch (dataSolicitante.BuroRegimenMatrimonial) {
          case "Usuario autorizado":
            regimenMatrimonial = "A"    
            break;

          case "Individual":
            regimenMatrimonial = "I"    
            break;

          case "Mancomunado":
            regimenMatrimonial = "J"    
            break;
        
          default:
            break;
        }

        if (dataSolicitante.Sexo == 1) {
          sexo = 1
        }else{
          sexo = 2
        }

        const Gender = dataSolicitante.Sexo === 1 ? 'M' : 'F'
        const strNacionalidad = 'MEXICANA'
        const IsForeing = false
        const IndoorNumber = !dataSolicitante.NumeroInterior ? '0' : dataSolicitante.NumeroInterior

        const listEstados = await this.getEstados()
        const arrState = listEstados.find(x => x.Id === dataSolicitante.Estado)
        const State = arrState.Nombre

        const objConsultaSisec = { 
              BrokerEmployeeId: this.SocGuid,
              Score: JSON.stringify(reporte),
              ExternalFolio: idBuroCreditoConsulta,
              FirstName: dataSolicitante.Nombres,
              SecondName: dataSolicitante.SegundoNombre,
              LastName: dataSolicitante.ApellidoPaterno,
              SecondLastName: dataSolicitante.ApellidoMaterno,
          //SocialReason: null,
          //ConstitutionDate: null,
              Gender:sexo,
          //IsForeing,
          //Nationality: strNacionalidad,
              Email: dataSolicitante.EMail,
              Birthday: `${dataSolicitante.FechaNacimiento} 00:00:00.000`,   //"Birthday": "2005-11-30T11:18:15.4223008-06:00",
              CURP: dataSolicitante.Curp,
              RFC: dataSolicitante.Rfc,
              Phone: dataSolicitante.TelefonoCelular,
              PersonType: 'Fisico',
              Street: dataSolicitante.Calle,
              OutdoorNumber: dataSolicitante.NumeroExterior,
              IndoorNumber,
              Colony: dataSolicitante.Colonia,
              CP: dataSolicitante.CodigoPostal,
              Municipality: dataSolicitante.Municipio,
              City: dataSolicitante.Ciudad,
              State,
          //IsEmployee: false,
          //TermsAndConditions: true,
              Origin: '2',
              IsReusedScore: isReusedScore,
              AccountNumber: dataSolicitante.BuroNumeroCuenta,
              MatrimonialRegimeCode: regimenMatrimonial
        }

        const TOKEN_SISEC = await this.getTokenSisec()
        const { access_token } = TOKEN_SISEC.data

        //const URL_API_SISEC = 'https://apicirculocredito.sisec.mx/api/CirculoCredito/Consulta'
        // const URL_API_SISEC = 'https://apicirculocredito.sisec.mx/api/CirculoCredito/Consulta' // PROD

        const URL_API_SISEC = 'https://apicirculocredito.sisec.mx/api/BuroCredito/Consulta'   // buro de credito

        const resp = await this.$axios.post(URL_API_SISEC, objConsultaSisec, { 
          headers : {
            'Authorization': `Bearer ${access_token}`,
            'Content-Type': 'application/json; charset=UTF-8'
          }
        })

        const { status, data } = resp

        if ( status !== 200 ) {

          return { 
            errorConsultaSisec: 'Ocurrio un error en registro de consulta SISEC',
            strErrorConsultaSisec: 'No se realizó la consulta en siste SISEC',
            data: null
          }

        }

        const { Folio, Success, Message } = data

        if ( !Success ) {

          return { 
            errorConsultaSisec: 'Ocurrio un error en registro de consulta SISEC',
            strErrorConsultaSisec: Message,
            data: null
          }

        }

        const { errorFolioSisec, strErrorFolioSisec, objContenidoFolioSisec } = await this.SaveFolioSisec({ Folio, idBuroCreditoConsulta })

        if ( errorFolioSisec ) {

          return { errorConsultaSisec: errorFolioSisec, strErrorConsultaSisec: strErrorFolioSisec, data: null }

        }

        return { errorConsultaSisec: null, strErrorConsultaSisec: null, data: Folio }

      } catch (error) {

        console.error('Error consultaSISEC ' + error)

        return { errorConsultaSisec: 'Ocurrio un error de sistema', strErrorConsultaSisec: error, data: null }
      }
    },

    async SaveFolioSisec ({ Folio, idBuroCreditoConsulta }) {

      try {

        const objRequestSaveFolio = {
          strApiKey: this.key,
          strMetodo: 'SaveFolioConsultaBuroCreditoSisec',
          objSave: {
            Folio,
            idBuroCreditoConsulta
          }
        }

        const { intEstatus } = await this.$axios.post('/', objRequestSaveFolio, {headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})

        if ( intEstatus ) {

          return { errorFolioSisec: 'Error en registro de folio', strErrorFolioSisec: 'Ocurrio un error al registrar folio de SISEC', objContenidoFolioSisec: null }

        }
        
        // const { intEstatus, objContenido } = resp

        // this.$vs.notify({
        //   title:'Registro exitoso de Folio SISEC',
        //   text:`Se registro el folio ${Folio}`,
        //   position:'top-right'
        // })

        return { errorFolioSisec: null, strErrorFolioSisec: null, objContenidoFolioSisec: Folio }

      } catch (error) {
        console.error('Error SaveFolioSisec ' + error)

        // this.$vs.notify({
        //   title:'Ocurrio un error de sistema',
        //   text:error,
        //   color:'danger',
        //   position:'top-right'
        // })

        return { errorFolioSisec: 'Ocurrio un error de sistema', strErrorFolioSisec: error, objContenidoFolioSisec: null }
      }

    },

    async dameSolicitante ({ email }) {

      try {

        const objRequestDameSolicitante = {
          strApiKey: this.key,
          strMetodo: 'DameSolicitante',
          objSolicitante: {
            EMail: email
          }
        }

        const resp = await this.$axios.post('/',objRequestDameSolicitante,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
        
        if ( resp.intEstatus ) return { strErrorSolicitante: resp.strError, objContenidoSolicitante: null }

        return { strErrorSolicitante: null, objContenidoSolicitante: resp.data.objContenido }

      } catch (error) {

        console.error(error)

        return { strErrorSolicitante: error, objContenidoSolicitante: null }
      }

      let self=this
      var objRequestDameSolicitante = {
        strApiKey: this.key,
        strMetodo: 'DameSolicitante',
        objSolicitante: {
         EMail: this.email
        }
      }
      this.$axios.post('/',objRequestDameSolicitante,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.solicitante=response.data.objContenido
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en DameSolicitante',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },

    async getEstados() {

      try {
        const objRequestListaCasoEstatus = {
          strApiKey: this.key,
          strMetodo: 'ListaEstados',
        }

        const { data } = await this.$axios.post('/', objRequestListaCasoEstatus, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
        const { intEstatus, objContenido } = data

        if ( intEstatus ) {
          this.$vs.notify({
            title: 'Ocurrio un error en ListaEstados',
            text: response.data.strError,
            color: 'danger',
            position: 'top-right',
          })

          return []
        }

        return objContenido
        
      } catch (error) {
        self.$vs.notify({
          title: 'Ocurrio un error de sistema',
          text: error,
          color: 'danger',
          position: 'top-right',
        })

        return []
      }
		},

    getApikey(){
      const { ApiKey } = JSON.parse(localStorage.getItem('userInfo'))

      this.key = ApiKey
    },

  }
}
</script>

<style scoped>
  .content-loading {
    width: 100%;
    height: calc(100vh - 25px);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    letter-spacing: 0.25em;
  }

  .content-loading img {
    width: 300px;
  }
</style>